import { nextTick } from 'vue'
import { getQueryString } from '@shein/common-function'
import { componentStateFactory, pageStateFactory, resultsFactory } from './state'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { transformSaleTags } from 'public/src/pages/common/promotion/logic/transformSaleTags'
import { preFormatFilterAttrs, getAttrExporConfigByAbt, getStickyConfig, getSearchDiff,
  getFilterBarQueryParams,
} from './utils'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { getExposedGoods } from 'public/src/pages/common/refresh_list_strategy/helpers/index'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
// import { removeExposedProducts } from 'public/src/pages/common/refresh_list_strategy/helpers/index.js'
import { isBatchOperation, renderAndBathchItems } from './productBatchRenderer.js'
import { rafPromiseManager } from './rafPromiseManager.js'
const { rafPromise, cancelRafPromises } = rafPromiseManager()

const mutations = {
  resetLocals(state, payload) {
    if (payload?.locals) return state.Locals = payload.locals
    if (typeof window === 'undefined') return

    const { WEB_CLIENT, IS_RW, SiteUID, host, lang, langPath, appLanguage, GB_cssRight, is_include_critical_css, LAZY_IMG, LAZY_IMG_PIXEL, LAZY_IMG_SOLID_COLOR, IMG_LINK, dateLangMap, RESOURCE_SDK, PUBLIC_CDN } = gbCommonInfo
    const currency = UserInfoManager.get({ key: 'currency' })
    state.Locals = { WEB_CLIENT, IS_RW, SiteUID, host, lang, langPath, appLanguage, GB_cssRight, is_include_critical_css, LAZY_IMG, LAZY_IMG_PIXEL, LAZY_IMG_SOLID_COLOR, IMG_LINK, dateLangMap, currency, RESOURCE_SDK, PUBLIC_CDN }
  },
  resetState(state, { payload, PageStateArgs, ComponentStateArgs }) {
    if (payload.language) state.Language = payload.language
    if (!state.GoodsItemInfoServ) state.GoodsItemInfoServ = new GoodsItemInfo({ itemLanguage: state.Language, locals: state.Locals })

    state.PageState = pageStateFactory(PageStateArgs)
    state.ComponentState = componentStateFactory(ComponentStateArgs)
    state.Results = resultsFactory()
  },
  dealingFlowDataV2(state, {
    flowDataV2,
    cat_info,
  }) {
    const { flowStoreFollowing, flowSurveyInfo } = flowDataV2 || {}
    if (cat_info.requestType !== 'nextpage') {
      // 融合之后走这里：public/src/pages/product_app/store/modules/product_list_v2/action_flow.js
      state.ComponentState.ProductList.flowDataV2.flowStoreFollowing = flowStoreFollowing || {}
      if (!isSwitchBffApiVersion(['surveyQuestion_v1'])?.surveyQuestion_v1) {
        state.ComponentState.ProductList.flowDataV2.flowSurveyInfo = flowSurveyInfo || {}
      }
    }
  },

  // 初始化、筛选接口init初始化
  dealingInitOrFilterInfoNew(state, { atomicParams, listAbtResult, cccOperation, cccxConfig, cccxFilterConfig, shouldUseCccxFilterChain, cat_info, mallInfo, filterAbtParam, cccConfig, sheinClubInfo, promotionInfoFromServer, picksMenuList, policyBanner, discountPageInfo, apolloConfig, frontFilterGoods } ) {
    const isFirstLoad = cat_info.requestType === 'firstload' // 首次加载
    const isSwitchTab = cat_info.requestType === 'switchTab' // 切tab(picks特有)
    const isRefresh = cat_info.requestType === 'refresh' // 筛选
    const isNextPage = cat_info.requestType === 'nextpage' // 翻页
    if (isFirstLoad || isSwitchTab) {
      listAbtResult && (state.Results['listAbtResult'] = listAbtResult)
      // Vue.set(state.Results, 'currentCat', currentCat)
      // Vue.set(state.Results, 'parentCats', parentCats)
      state.Results['cccOperation'] = cccOperation
      state.Results['cccxConfig'] = cccxConfig
      state.Results['cccxFilterConfig'] = cccxFilterConfig
      state.Results['shouldUseCccxFilterChain'] = shouldUseCccxFilterChain
      // Vue.set(state.Results, 'searchBannerCard', searchBannerCard)
      state.Results['mallInfo'] = mallInfo
      state.Results['filterAbtParam'] = filterAbtParam
      state.Results['cccConfig'] = cccConfig
      state.Results['sheinClubInfo'] = sheinClubInfo
      state.Results['atomicParams'] = atomicParams // init
      state.Results['policyBanner'] = policyBanner // init
      state.Results['apolloConfig'] = apolloConfig // init
      isFirstLoad && (state.Results['picksMenuList'] = picksMenuList) // init
    } else if (cat_info.useBffFilters > 0 && cat_info.requestType !== 'nextpage') {
      // bff下更新 productTypeSortConfig
      state.Results?.cccConfig && (state.Results.cccConfig.productTypeSortConfig = cccConfig?.productTypeSortConfig || [])
    }

    state.Results['cat_info'] = cat_info // init
    state.Results['promotionInfoFromServer'] = promotionInfoFromServer // init

    // 首次加载才需要修改 discountPageInfo，相当于 services/discount_channel_list/goods.js shouldRequestAllTab 的判断
    if (isFirstLoad || isRefresh) {
      state.Results['discountPageInfo'] = discountPageInfo
    }

    const originFrontFilterGoods = state.Results.frontFilterGoods
    const joinFrontFilterGoods =
      !isNextPage
        ? frontFilterGoods
        : originFrontFilterGoods
          ? frontFilterGoods
            ? `${originFrontFilterGoods},${frontFilterGoods}`
            : originFrontFilterGoods
          : frontFilterGoods
    state.Results['frontFilterGoods'] = joinFrontFilterGoods?.split(',').slice(0, 240).join()
  },

  // 初始化 bff Breadcrumbs
  initBreadcrumbs(state, { currentCat, parentCats }) {
    state.Results['currentCat'] = currentCat
    state.Results['parentCats'] = parentCats
  },
  // 商品接口相关info初始化
  dealingGoodsItemInfoNew(state, { cccConfig, cat_info, cccSeo, searchKeywords, bgBanner, sum, sumForPage, cardConfig }) {
    cancelRafPromises()
    if (searchKeywords && state.Results.searchKeywords) {
      searchKeywords.double_lang_correct = state.Results.searchKeywords?.double_lang_correct || searchKeywords.double_lang_correct
    }

    if (cat_info.requestType === 'firstload' || cat_info.requestType === 'switchTab') {
      cat_info.requestType === 'firstload' && (state.Results['bgBanner'] = bgBanner) // init
      state.Results['searchKeywords'] = searchKeywords
      state.Results['cardConfig'] = cardConfig
    }

    const { productTypeSortConfig, tagCloudSlotConfig } = state.Results['cccConfig']
    state.Results['cccConfig'] = Object.assign({}, cccConfig || {}, { productTypeSortConfig, tagCloudSlotConfig })
    state.Results['cccSeo'] = cccSeo

    state.Results['cat_info'] = cat_info // 补充cate_info信息。
    state.Results['sum'] = sum
    state.Results['sumForPage'] = sumForPage
  },

  /**
   * Start - dealingNewData -- 预处理请求数据并储存至 vuex
   * @param {*} payload response
   */
  dealingPageInfos(state, { atomicParams, listAbtResult, cccSeo, cccOperation, cccxConfig, cccxFilterConfig, shouldUseCccxFilterChain, cat_info, searchBannerCard, currentCat, parentCats, searchKeywords, sum, sumForPage, mallInfo, filterAbtParam, cccConfig, sheinClubInfo, promotionInfoFromServer, picksMenuList, picksName, bgBanner, policyBanner, discountPageInfo, apolloConfig, cardConfig, frontFilterGoods }) {
    // 模拟瀑布流
    // if (listAbtResult) {
    //   listAbtResult.ListLoadmore = listAbtResult.ListLoadmore || {}
    //   listAbtResult.ListLoadmore.p = 'type=waterfall'
    // }

    const isFirstLoad = cat_info.requestType === 'firstload' // 首次加载
    const isSwitchTab = cat_info.requestType === 'switchTab' // 切tab(picks特有)
    const isRefresh = cat_info.requestType === 'refresh' // 筛选
    const isNextPage = cat_info.requestType === 'nextpage' // 翻页

    // 搜索双语纠错信息需要继承下去
    if (searchKeywords && state.Results.searchKeywords) {
      searchKeywords.double_lang_correct = state.Results.searchKeywords?.double_lang_correct || searchKeywords.double_lang_correct
    }

    // firstload: 首次进入页面，switchTab: picks切换tab
    if (isFirstLoad || isSwitchTab) {
      if (state.Locals.IS_RW && currentCat?.brand_code == 28 && currentCat?.brande_detail?.detail_type == 2) {
        currentCat.select_name_multi = 'ROMWE X'
      }
      listAbtResult && (state.Results['listAbtResult'] = listAbtResult) // mock_flow_abt
      state.Results['currentCat'] = currentCat
      state.Results['parentCats'] = parentCats
      state.Results['searchKeywords'] = searchKeywords
      state.Results['cccSeo'] = cccSeo
      state.Results['cccOperation'] = cccOperation
      state.Results['cccxConfig'] = cccxConfig
      state.Results['cccxFilterConfig'] = cccxFilterConfig
      state.Results['shouldUseCccxFilterChain'] = shouldUseCccxFilterChain
      state.Results['searchBannerCard'] = searchBannerCard
      state.Results['mallInfo'] = mallInfo
      state.Results['filterAbtParam'] = filterAbtParam
      state.Results['cccConfig'] = cccConfig
      state.Results['sheinClubInfo'] = sheinClubInfo
      state.Results['atomicParams'] = atomicParams
      state.Results['policyBanner'] = policyBanner
      state.Results['apolloConfig'] = apolloConfig
      state.Results['cardConfig'] = cardConfig
      cat_info.requestType === 'firstload' && (state.Results['bgBanner'] = bgBanner)
      cat_info.requestType === 'firstload' && (state.Results['picksMenuList'] = picksMenuList)
      cat_info.requestType === 'firstload' && (state.Results['picksName'] = picksName)
    } else if (cat_info.useBffFilters > 0 && cat_info.requestType !== 'nextpage') {
      // bff下更新 productTypeSortConfig
      state.Results?.cccConfig && (state.Results.cccConfig.productTypeSortConfig = cccConfig?.productTypeSortConfig || [])
    }

    state.Results['cat_info'] = cat_info
    state.Results['sum'] = sum
    state.Results['sumForPage'] = sumForPage
    state.Results['promotionInfoFromServer'] = promotionInfoFromServer

    // 首次加载才需要修改 discountPageInfo，相当于 services/discount_channel_list/goods.js shouldRequestAllTab 的判断
    if (isRefresh || isFirstLoad) {
      state.Results['discountPageInfo'] = discountPageInfo
    }

    const originFrontFilterGoods = state.Results.frontFilterGoods
    const joinFrontFilterGoods =
      !isNextPage
        ? frontFilterGoods
        : originFrontFilterGoods
          ? frontFilterGoods
            ? `${originFrontFilterGoods},${frontFilterGoods}`
            : originFrontFilterGoods
          : frontFilterGoods
    state.Results['frontFilterGoods'] = joinFrontFilterGoods?.split(',').slice(0, 240).join()
  },
  initProductList({ ComponentState, GoodsItemInfoServ }, { catInfo, cardConfig }) {
    if (catInfo.requestType === 'firstload') {
      ComponentState.ProductList.goodsItemInfoServ = GoodsItemInfoServ

      const config = Object.assign({}, cardConfig)
      Object.freeze(config)
      ComponentState.ProductList['config'] = config
    }
  },
  dealingGoods({ PageState, ComponentState, Results, Language }, { goods, cat_info, promotionInfoFromServer, searchOptimize, isBlockingPointPush }) {    
    const nextpage = cat_info.requestType === 'nextpage'
    const originalGoods = Results.goods || []
    const { listSpuPic, searchSpuPic, LocFilterUpdate } = Results.listAbtResult || {}
    const { config } = ComponentState.ProductList
    const { exposeDynamicRecommendGood = [] } = Results // 当前页面中的所有点推商品

    let appendGoods = goods
    !nextpage && (Results['isBlockingPointPush'] = isBlockingPointPush) // 用首次就OK了,参见需求： UG-227427

    if (!goods?.length) {
      Results.goods = nextpage ? originalGoods : []
      !searchOptimize && (PageState.noMoreData = true) // 有搜索结果优化标志的因为会在客户端处理重复数据，导致goods为空，因此此情况下，不noMoreData处理
      PageState.searchOptimizeReqFlag = false
      Results.appendGoods = []
      nextTick(() => {
        if (typeof window !== 'undefined') {
          appEventCenter.emit('goodsLoadedOnPageList')
        }
      })
      return
    }

    // spu图去重
    const showListSpuPic = listSpuPic?.p?.ListSpuPic === 'show'
    const showSearchSpuPic = searchSpuPic?.p?.SearchSpuPic === 'show'

    if (showListSpuPic || showSearchSpuPic) {
      appendGoods.forEach(goods => {
        let spuImage = (goods?.spu_image || [])[0]?.medium_image
        if (PageState.spuImgMap[spuImage]) {
          goods.spu_image[0].medium_image = 'filteredSpuImg'
        }
        if (spuImage) PageState.spuImgMap[spuImage] = 1
      })
    }

    // 处理过滤，分页返回的接口数据过滤掉当前页面中已有的点推商品
    // let nowPageGoodsIdArr = originalGoods.map(item => item.goods_id)
    // console.log('当前页面点推商品', exposeDynamicRecommendGood)
    appendGoods = appendGoods.filter(item => !exposeDynamicRecommendGood.includes(`${item.goods_id}`))

    // 新的商品预处理服务 - 目前只有促销和分布式筛选
    appendGoods = dealProductsPretreatInfo({
      products: appendGoods,
      itemConfig: config,
      promotionInfoFromServer,
      language: Language,
      searchFilterDependencies: {
        picTopNav: Results.picTopNav,
        cloudTags: Results.cloudTags,
        filterWords: typeof window === 'undefined' ? [] : getQueryString({ key: 'filterWords' })?.split(','),
        NoSellingPoint: LocFilterUpdate?.param?.NoSellingPoint === 'new',
        SameFilter: LocFilterUpdate?.param?.SameFilter === 'yes'
      }
    })

    function checkGoodsLength(goods) {
      if (goods.length === 0) {
        // 在这里一整页数据才加载完成, 并且 dom 也渲染完成, 瀑布流也塞好了商品
        nextTick(() => {
          if (typeof window !== 'undefined') {
            appEventCenter.emit('goodsLoadedOnPageList')
          }
        })
        return false
      }
      return true
    }

    // 分块渲染
    async function setGoods(goods, chunkSize, isInitialCall) {
      if (!checkGoodsLength(goods)) return
      await rafPromise()
      const goodsChunk = goods.slice(0, chunkSize)
      const productsList = [...(Results.goods || []), ...goodsChunk]
      Results.goods = (nextpage || !isInitialCall) ? productsList : goodsChunk
      setGoods(goods.slice(chunkSize), chunkSize, false)
    }

    function setGoodsForInitialCall(goods) {
      if (!checkGoodsLength(goods)) return
      let chunkSize = 1
      //  筛选全部渲染，避免商品过多出现闪屏【如：筛选返回60商品，筛选前滚动10页，逐个渲染需要渲染60次，切页面逐步下拉，视觉上有闪动】
      cat_info.requestType === 'refresh' && (chunkSize = Math.floor(goods.length / 3) || 1)

      // 分页请求时
      if (nextpage) {
        setGoods(goods, chunkSize, false)
        return
      }

      requestAnimationFrame(() => {
        markPoint({ eventName: 'ProductListV2Container', measureFrom: 'PageResolveData' })
        markPoint({ eventName: 'XHRLoadGoods', measureFrom: 'ApiResponseGoods' })

        // 策略优先展示图片, 优化用户体验
        // waterfallRenderDirectionLTR 用于锁定瀑布流方向，保持ssr渲染的方向
        ComponentState.ProductList.waterfallRenderDirectionLTR = true
        ComponentState.ProductList.isFirstItem = true // 首次和筛选
        renderAndBathchItems(goods, Results, () => {
          ComponentState.ProductList.isFirstItem = false
        }, (updatableGoods) => {
          requestAnimationFrame(() => {
            // 会导致策略会立即生效，requestAnimationFrame在下次渲染之前重置。
            ComponentState.ProductList.waterfallRenderDirectionLTR = false
          })
          setGoods(updatableGoods, chunkSize, false)
        })
      })
    }

    PageState.noMoreData = nextpage ? (appendGoods.length + originalGoods.length) >= Results.sum : appendGoods.length >= Results.sum
    
    PageState.searchOptimizeReqFlag = false
    Results.appendGoods = appendGoods

    if (!PageState.isSpaPage && cat_info.requestType === 'firstload') {
      ComponentState.ProductList.isFirstItem = true
      // 只有 ssr 渲染 + 首页数据, 才一次性渲染一页商品
      Results.goods = appendGoods
      nextTick(() => {
        if (typeof window !== 'undefined') {
          appEventCenter.emit('goodsLoadedOnPageList')
          ComponentState.ProductList.isFirstItem = false
        }
      })
    } else {   
      // 其他场景 (SPA 页内跳转; 筛选), 一律分块渲染, 提高性能
      setGoodsForInitialCall(appendGoods)
    }
  },
  deleteUnexposedGoods({ ComponentState, Results }, deleteIndex){    // 未曝光的商品在这里删除，然后拼接最新商品
    ComponentState.productListDeleteFlag = true
    Results.goods.splice(deleteIndex + 1)
  },
  removeUnexposedGoods({ ComponentState, Results }) {
    const originalGoods = Results.goods || []
    // console.log('originalGoods 删除前：', JSON.stringify(originalGoods.map(item => item.goods_id)))

    // 删除前锁定瀑布流
    ComponentState.productListDeleteFlag = true

    // 已经分页加载的商品 处理删除商品逻辑
    // 已经加载的商品可能包含点推回来的商品，点推回来的商品会当做已经曝光的商品处理，避免被误删
    // for (let i = originalGoods.length - 1; i >= 0; i--) {
    //   const isExposedGood = Results.exposedGoods.find(item => item.goods_id == originalGoods[i].goods_id)
    //   if (!isExposedGood) {
    //     originalGoods.splice(i, 1)
    //   }
    // }

    /**
     * 使用最大曝光商品的深度来决定删除的商品
     * 1. 获取瀑布流最底的两个曝光商品
     * 2. 将最底两个曝光商品在 originalGoods 中找到最大 index
     * 3. 倒序删除未曝光商品，直到最大 index 的位置停止
     */
    const exposedGoods = Results.exposedGoods

    const { maxIndex } = getExposedGoods({ exposedProducts: exposedGoods, goods: originalGoods })

    // getExposedGoods

    // const lastExposedGood = exposedGoods[exposedGoods.length - 1]
    // const secondLastExposedGood = exposedGoods[exposedGoods.length - 2]

    // const lastExposedGoodIndex = originalGoods.findIndex(item => item.goods_id == lastExposedGood.goods_id)
    // const secondLastExposedGoodIndex = originalGoods.findIndex(item => item.goods_id == secondLastExposedGood.goods_id)

    // const removeEndedIndex = secondLastExposedGoodIndex > lastExposedGoodIndex ? secondLastExposedGoodIndex : lastExposedGoodIndex

    for (let i = originalGoods.length - 1; i >= 0; i--) {
      if (i > maxIndex) {
        originalGoods.splice(i, 1)
      }
    }

    // console.log('originalGoods 删除后：', JSON.stringify(originalGoods.map(item => item.goods_id)))
  },
  filterUnexposedGoods({ ComponentState, Results }, { exposedCardList, recommendCardList } ){
    // 过滤未曝光的商品
    ComponentState.productListDeleteFlag = true
    const recommendProductIds = new Set(
      recommendCardList.map(item => item.goods_id).filter(Boolean)
    )
    const { maxIndex } = getExposedGoods({ exposedProducts: exposedCardList, goods: Results.goods })
    
    Results.goods = Results.goods.filter((item, i) =>
      !item?.goods_id || i <= maxIndex || recommendProductIds.has(item?.goods_id)
    )
  },
  updateSearchOptimizeReqFlag({ PageState }, value){ // 搜索优化-请求🔒标记更新状态
    PageState.searchOptimizeReqFlag = value
  },
  dealingOriginFilters({ Results }, { cat_info, dailyDates, filterCates, filterPrices, picTopNav, picTopNavCate }) {
    Results.dailyDates = dailyDates ? dailyDates : {}
    Results.filterCates = filterCates?.length ? filterCates : []

    const firstloadPage = cat_info.requestType === 'firstload' || cat_info.requestType === 'switchTab'

    // 价格筛选
    const isPriceFilter = ['min_price', 'max_price'].some(key => cat_info[key] || cat_info[key] === 0);
    (firstloadPage || !isPriceFilter) && (Results['filterPrices'] = filterPrices ? filterPrices : {})
    // 图文导航set
    firstloadPage && (Results.picTopNav = picTopNav ? picTopNav : [])
    // 图文子类目数据set
    Results.picTopNavCate = picTopNavCate || []
  },
  updatePicTopNav({ Results }, picTopNav) {
    Results['picTopNav'] = picTopNav ? picTopNav : []
  },
  dealingAttributes({ Locals: { SiteUID }, PageState, Results }, { filterAttrs, isPlusSize }) {
    filterAttrs = filterAttrs?.filter(_ => _.attr_name)
    if (!filterAttrs?.length) return Results['filterAttrs'] = []
    // 移除了函数入参 ListAttrSequence, appLanguage, hotFilterAttrs
    preFormatFilterAttrs({
      SiteUID,
      filterAttrs,
      isPlusSize,
      staticAttrSet: PageState.staticFilterType != 'tsp' && PageState.staticAttrSetId && Results.filterAttrs.find(_ => _.attr_id === PageState.staticAttrSetId)
    })
    // PageState.staticAttrSetId = ''
    Results.filterAttrs = filterAttrs
  },
  dealingKidsTag ({ Results }, { kidsTag }) {
    Results['kidsTag'] = kidsTag
  },
  dealingFilterTsps({ Results, PageState }, { filterTsps }) {
    if (!Array.isArray(filterTsps) || !filterTsps.length) {
      Results.filterTsps = []
      return
    }

    const hasStaticTsp = PageState.staticAttrSetId && PageState.staticFilterType == 'tsp'
    const tsps = filterTsps.map(({ tagGroupId, tagGroupName, tagGroupValue, tagList = [], nodeType }) => {
      // 每一项tag加上tagGroupId

      // 处理 staticFilterType
      if (hasStaticTsp && tagGroupId === PageState.staticAttrSetId) {
        const oldIndex = Results.filterTsps.findIndex(_ => _.tagGroupId === PageState.staticAttrSetId)
        if (oldIndex > -1) return Results.filterTsps[oldIndex]
      }

      const list = tagList.map(({ tagId, tagName, nodeType }) => ({ tagName, tagId: `${tagGroupId}_${tagId}`, nodeType }))
      return { tagGroupId, tagGroupName: tagGroupValue || tagGroupName, tagList: list, nodeType }
    })
    Results.filterTsps = tsps
    PageState.staticAttrSetId = ''
  },
  dealingCloudTags ({ Locals: { lang }, Language, Results }, { listAbtResult, cloudTags, cat_info }) {
    const { AllListQuickShip, listquickshipKey, listlabelQuickship } = listAbtResult || {}
    const { mallCode } = cat_info || {}
    // 列表指定mall的场景（入口跳转到列表带mallCode），过滤掉mall标签筛选，目的不让用户进行任何mall筛选
    cloudTags = cloudTags?.filter(_ => +_.type === 1 ? !mallCode : _.tag_id)
    if (!cloudTags?.length) return Results.cloudTags = []
    const filterTags = []
    cloudTags.forEach(_ => {
      if (_.is_sale_tag && _.promotionInfo?.typeId) {
        // 促销标签展示逻辑 样式内置 TODO
        _.tag_name = transformSaleTags({ promotionInfo: _.promotionInfo, lang, language: Language })
        _.icon = 'sui_icon_activity_24px'
        _.promotion_tag = 1
      }
      // 新版标签云组件补参
      _.value = _.tag_id || ''
      _.label = _.tag_name || ''

      if (+_.type === 1) {
        if (cat_info.useBffFilters) {
          _.mall_tag = 1
          filterTags.push(_)
          return
        }
        const { language_mall_name, mall_tags } = Results?.mallInfo?.find(mall => mall.mall_code == _.tag_id) || {}
        const isOverSeaMall = mall_tags?.includes('4')
        const isLocalMall = mall_tags?.includes('5')
        // 只展示，仅是本地mall或跨境mall的标签
        const isLocalMallOnly = isLocalMall && !isOverSeaMall
        const isOverSeaMallOnly = !isLocalMall && isOverSeaMall
        if ((isLocalMallOnly || isOverSeaMallOnly) && language_mall_name) {
          _.label = language_mall_name
          _.mall_tag = 1
          _.local_mall = 0
          if (isLocalMallOnly) {
            _.icon = 'sui_icon_shipping_12px'
            _.local_mall = 1
          }
          filterTags.push(_)
        }
      } else if (+_.type === 2) {
        if (_.tag_id == 'quickship') {
          _.isNewQs = listlabelQuickship?.p?.listlabelQuickship === 'newQuickship'
          const showQuickShip = AllListQuickShip?.p === 'type=B'

          const listquickKey = listquickshipKey?.p?.listquickKey === 'newKey'
          const quickShipText = listquickKey ? Language.SHEIN_KEY_PWA_30900 : Language.SHEIN_KEY_PWA_22276

          if (!showQuickShip) return
          _.icon = 'sui_icon_shipping_12px'
          _.label = quickShipText
          _.quickship_tag = 1
          filterTags.push(_)
        }
      } else if (_.is_black_friday_tag) {
        _.icon = 'sui_icon_hot_12px'
        filterTags.push(_)
      } else {
        filterTags.push(_)
      }
    })

    Results.cloudTags = filterTags
  },
  dealingBannerTag({ Results }, { bannerTag = {} }) {
    const handleBannerTag = (data = {}) => {
      // 更改筛选项
      if (Results?.bannerTag?.billno) {
        // 当前筛选项不存在一键购标签
        if (!bannerTag?.showTag) {
          Results.bannerTag = {
            ...Results.bannerTag,
            showTag: 0,
          }
        } else {
          Results.bannerTag = {
            ...Results.bannerTag,
            showTag: 1,
          }
        }
        return
      }
      if (!data) return false

      Results.bannerTag = data
    }

    handleBannerTag(bannerTag)
  },
  updateSpecificAttr({ Results }, { key, value }) {
    Results[key] = value
  },
  dealingTrackings({ Results }, { cat_info, fhContext, googleContext, tracking, request_ext, goodsCrowId }) {
    Results.tracking = tracking
    Results.request_ext = request_ext
    Results.goodsCrowId = goodsCrowId
    // nextpage不更新筛选需要维持第三方埋点原先值
    if (cat_info.requestType !== 'nextpage') {
      Results.fhContext = fhContext // init接口
      Results.googleContext = googleContext // init接口
    }
  },
  /**
   * Start - initState -- 将 vuex 数据装载至组件
   * @param {*} payload getters + payload部分数据
   */
  initPage({ PageState }, { catInfo, searchTraceId }) {
    PageState.ready = true
    PageState.hasFilt = [
      'child_cat_id',
      'attr_ids',
      'brand_ids',
      'tag_ids',
      'min_price',
      'max_price',
      'pic_nav_id',
      'tsp_ids'
    ].some(key => catInfo[key] || catInfo[key] === 0)
    PageState.searchTraceId = searchTraceId
  },
  initSearchCCCCard({ ComponentState, Results }, { catInfo }) {
    if (catInfo.requestType !== 'firstload' || !Results?.searchBannerCard?.searchCCCCard) return
    ComponentState.searchCCCCard = Results?.searchBannerCard?.searchCCCCard

  },
  initBrandBanner({ ComponentState }, { locals: { IS_RW, IMG_LINK }, catInfo, currentCat, language, appendGoods }) {
    if (catInfo.requestType !== 'firstload') return
    if (catInfo.type === 'selection' && appendGoods?.[0]?.goods_img && currentCat.brande_detail) {
      let logoImg = currentCat.brande_detail.brand_logo
      let description = decodeURI(encodeURI(currentCat.brande_detail.brand_introduction).replace(/%C2%A0/g, '%20'))
      if (IS_RW && currentCat.brand_code == 28 && currentCat.brande_detail.detail_type == 2) {
        logoImg = IMG_LINK.romwex
        description = language.SHEIN_KEY_PWA_19574
      }
      ComponentState.BrandBanner = logoImg && description
        ? {
          bannerImg: appendGoods[0].goods_img,
          logoImg,
          description
        }
        : null
    }
  },
  initBgBanner({ ComponentState }, { cccConfig }) {
    const { bgBanner } = cccConfig
    ComponentState['BgBanner'] = bgBanner && bgBanner.src ? bgBanner : null
  },
  initListTopInfo({ ComponentState }, { appendGoods, catInfo, searchKeywords, goods }) {
    const hideListTopInfo = catInfo.type === 'search' && getSearchDiff(catInfo).hideListTopInfo
    if (hideListTopInfo) return
    const nextStatus = {
      show: !!goods.length || !!appendGoods.length,
      showCoupon: catInfo.type === 'selection' && catInfo.ShowTip === '1',
      showSilLabel: catInfo.type === 'search' && /^s1`ListSearch/.test(catInfo.ici) && catInfo.sil_type, // sil c1 c2 非 entry 暂时只能这样判定
      showPostCorrection: searchKeywords?.is_suggest_res,
    }
    if (catInfo.requestType === 'firstload') {
      ComponentState.ListTopInfo = nextStatus
    } else {
      ComponentState.ListTopInfo.show = nextStatus.show
      ComponentState.ListTopInfo.showSilLabel = nextStatus.showSilLabel
    }
  },
  initQueryParams ({ ComponentState }, { catInfo }) {
    ComponentState.queryParams = getFilterBarQueryParams(catInfo)
  },
  updateQueryParams ({ ComponentState }, params) {
    ComponentState.queryParams = {
      ...ComponentState.queryParams,
      ...params
    }
  },
  initFilterBar({ Results, ComponentState, Locals: { currency } }, { listAbtResult, cccOperation: { promotion_label }, catInfo, cloudTags, dailyDates, filterCates, filterAttrs, filterPrices, filterTsps, picTopNavCate, picTopNav, cccConfig, cccxFilterConfig, picksMenuList, shouldUseCccxFilterChain, showStoreDefaultWords, kidsTag, isTopTrendResultPage }) {
    if (catInfo.requestType === 'nextpage') return

    const { ListTopNavigation, ListTopNavigationJson, recSwitch, SearchTopNavigation, ListLeftFilter, SearchLeftFilter, MostPopular, ListFilter } = listAbtResult
    let is_navtype_tagdata
    const recSwitch124 = recSwitch?.p?.recSwitch?.split('|')?.includes('124')
    const abtParam = ((recSwitch124 ? ListTopNavigation : ListTopNavigationJson) || SearchTopNavigation)?.p
    if (typeof abtParam === 'string') {
      // 说明是搜索图文导航的 abt, 它还是一个字符串, 还未迁移到新 abt 平台
      is_navtype_tagdata = abtParam.includes('nav_version')
    } else if (abtParam && typeof abtParam === 'object') {
      is_navtype_tagdata = Boolean(abtParam.nav_version)
    } else {
      is_navtype_tagdata = false
    }

    const filterLeftkey = SearchLeftFilter || ListLeftFilter || {}
    const exportConfig = getAttrExporConfigByAbt({ ListFilter, MostPopular, pageName: catInfo?.pageName })

    try {
      ComponentState.FilterBar = {
        filterData: JSON.parse(JSON.stringify({
          kidsTag,
          dailyDates,
          filterCates,
          filterAttrs,
          filterPrices,
          cloudTags,
          filterTsps,
          picTopNavCate,
          bannerTag: Results.bannerTag
        })),
        filterConfig: {
          currency,
          is_navtype_tagdata,
          topSucking: true,
          isCccxFilterConfig: shouldUseCccxFilterChain,
          filterLeftShow: filterLeftkey.p === 'type=A', // 左侧筛选
          isTopTrendResultPage, // v.趋势搜索结果页屏蔽该功能
          promotion_label,
          tagCloudSlotConfig: cccConfig?.tagCloudSlotConfig || [], // 标签插坑
          cccxFilterConfig: cccxFilterConfig?.filterRule || [], // cccxFilter
          productTypeSortConfig: cccConfig.productTypeSortConfig || [], // productType 外露标签云配置
          exposureTsp: exportConfig.isExportFilter ? true : false, // 外露tsp在筛选坑位
          hidePicTopNav: getSearchDiff(catInfo).hidePicTopNav, // 隐藏顶部图文导航
          hasPicNavTop: getSearchDiff(catInfo).hasPicNavTop, // 标签云
          ...exportConfig,
          ...getStickyConfig({ catInfo, picTopNav, picksMenuList, listAbtResult, bannerTag: Results.bannerTag, showStoreDefaultWords, isTopTrendResultPage }),
        }
      }
    } catch (error) {
      console.error('mutations/initFilterBar', error)
    }
  },
  initNoResult({ ComponentState, PageState: { hasFilt } }, { appendGoods, catInfo, goods }) {
    if (goods.length || appendGoods.length) return
    ComponentState.NoResult = {
      description: false,
      reselect: false,
      searchInput: false,
      searchTips: false,
      searchInAll: false,
      searchFeedback: false,
      searchRelated: false,
      noResultTipStyle: false,
    }
    if (hasFilt) {
      Object.assign(ComponentState.NoResult, {
        description: true,
        reselect: true,
      })
    } else if (catInfo.type === 'search') {
      if (/^s1`ListSearch/.test(catInfo.ici) && catInfo.sil_type) {
        Object.assign(ComponentState.NoResult, {
          searchTips: true,
          searchInAll: true,
          searchFeedback: true,
          searchRelated: true
        })
      } else if (getSearchDiff(catInfo).isStoreSearch) {
        Object.assign(ComponentState.NoResult, {
          searchTips: true,
          searchInAll: false,
          searchFeedback: false,
          searchRelated: false
        })
      } else {
        Object.assign(ComponentState.NoResult, {
          searchTips: true,
          searchInput: true,
          searchFeedback: true,
          searchRelated: true
        })
        // 搜索框吸顶
        Object.assign(ComponentState.NoResult, {
          icon: true,
          searchInput: false,
          searchFeedback_Mid: true,
          searchFeedback: false,
        })
        // 搜索没结果新样式
        Object.assign(ComponentState.NoResult, {
          searchTips: true,
          description: false,
          reselect: false,
          searchInput: false,
          searchInAll: false,
          searchFeedback_Mid: false,
          searchRelated: true,
          searchFeedback: false,
          noResultTipStyle: true
        })
        // 搜索没结果新样式 (品牌结果页)
        if (getSearchDiff(catInfo).isBrandSearch) {
          Object.assign(ComponentState.NoResult, {
            // description: true,
            reselect: false,
            searchInput: false,
            // searchTips: false,
            searchInAll: false,
            searchFeedback: false,
            searchRelated: false,
            noResultTipStyle: false,
          })
        }
      }
    } else {
      Object.assign(ComponentState.NoResult, {
        description: true,
      })
    }
  },
  // 底部信息 : 控制推荐显隐 + 底部描述文案
  initListBottomInfo({ ComponentState }, { language, cccOperation: { mobile_seo_text }, catInfo }) {
    if (catInfo.requestType !== 'firstload' || ['store'].includes(catInfo.search_type)) return
    ComponentState.ListBottomInfo = {
      seoText: mobile_seo_text,
      bttLang: language.SHEIN_KEY_PWA_15051,
    }
  },
  // 搜索反馈入口
  initSearchFeedback({ ComponentState }, { language, cccOperation: { mobile_feedback_entry }, catInfo }) {
    if (!mobile_feedback_entry) return
    if (catInfo.requestType === 'firstload') {
      ComponentState.SearchFeedback = {
        showTips: false,
        lock: false,
        hrefTarget: mobile_feedback_entry.hrefTarget,
        text: mobile_feedback_entry.text,
        sendLang: language.SHEIN_KEY_PWA_16977,
        thankLang: language.SHEIN_KEY_PWA_15692
      }
    } else if (+catInfo.page === 3 && catInfo.type === 'search' && ComponentState.SearchFeedback) {
      ComponentState.SearchFeedback.showTips = true
    }
  },

  initFilterAbt({ ComponentState }, { filterAbtParam }) {
    ComponentState.filterAbtParam = filterAbtParam
  },
  // 改变页面的状态
  setPageState({ PageState }, result) {
    Object.assign(PageState, result)
  },

  // 改变banner标签状态
  setBannerTagState({ Results }, result = {}) {
    const bannerTag = {
      ...Results.bannerTag,
      ...result,
    }
    Results.bannerTag = bannerTag
  },
  // 更新一键购新人引导/成人弹框的状态
  updateDialogShowStatus(state, { dialogName = '', result = false }) {
    state[dialogName] = result
  },
  // 设置购物车加车数量
  setGoodsCheckoutNum({ Results }, { checkoutedInfo = {}, showCheckout = false, showCheckoutNum = false }) {
    const originalGoods = Results.goods || []
    const checkoutedGoodsKeys = Object.keys(checkoutedInfo)

    originalGoods.forEach((item, index) => {
      const { goods_sn } = item
      if (checkoutedGoodsKeys.includes(goods_sn)) {
        const checkoutNum = checkoutedInfo[goods_sn].quantity

        if (Results.goods?.[index] && checkoutNum && showCheckoutNum) {
          Results.goods[index]['checkoutNum'] = checkoutNum
        }

        if (Results.goods?.[index] && showCheckout) {
          Results.goods[index]['showCheckout'] = showCheckout
        }
      }
    })
  },
  /**
   * @description: 首屏加载完成后处理数据
   * 性能优化：减少首屏加载时间
   */
  processDataAfterInitialLoad({ Results, ComponentState }, payload) {
    if (!payload) return
    let { filterCates } = payload
    filterCates = JSON.parse(JSON.stringify(filterCates?.length ? filterCates : []))
    Results.filterCates = filterCates
    ComponentState.FilterBar.filterData.filterCates = filterCates
  },
  // 点刷需求存储已曝光商品id
  updateExposedGoods({ Results }, { goodsId, divideTime }) {

    const exposedGoods = [ ...Results.exposedGoods, divideTime ? { goods_id: goodsId, divideTime } : { goods_id: goodsId } ]

    Results.exposedGoods = exposedGoods
  },
  // 清空点后刷相关数据
  resetExposeGoods({ Results }) {
    Results.exposedGoods = []
    Results.exposedGoodsWithSpu = []
    Results.exposeDynamicRecommendGood = []
  },
  // 点刷需求存储已曝光商品id+spu
  updateExposedGoodsWithSpu({ Results }, { goodsId, spuId, divideTime }) {
    if (divideTime === 'recommend') {
      // 记录 页面中已经曝光的点推商品
      const exposedGoods = [...Results.exposeDynamicRecommendGood, `${goodsId}`]
      Results.exposeDynamicRecommendGood = exposedGoods
      return
    }

    const exposedGoods = [...Results.exposedGoodsWithSpu, `${goodsId}-${spuId}`]

    Results.exposedGoodsWithSpu = exposedGoods
  },
  // 处理点刷逻辑
  // 初始化搜索卡片
  initSearchBannerCard({ Results, ComponentState }, { searchBannerCard }) {
    Results['searchBannerCard'] = searchBannerCard
    searchBannerCard?.searchCCCCard && (ComponentState['searchCCCCard'] = searchBannerCard.searchCCCCard)
  },
  setCardConfig({ ComponentState }, { cardConfig }) {
    ComponentState['ProductList']['config'] = cardConfig
  }
}

export default mutations
